import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public showMenu: any = false;

  public allStudentInfo: any[] = [];
  public certificate: any;
  public marksheet: any;

  public baseUrl: string = "https://api.aicasindia.in/api/";
  // public baseUrl: string = "http://localhost:2222/api/";

  constructor(
    private afs: AngularFirestore,
    public http: HttpClient
  ) {
    this.certificate = localStorage.getItem('certificate') ? localStorage.getItem('certificate') : null;
    this.marksheet = localStorage.getItem('marksheet') ? localStorage.getItem('marksheet') : null;

    this.allStudentInfo = localStorage.getItem('allStudent') ? JSON.parse(localStorage.getItem('allStudent')) : [];
  }


  //! //////////////////////////////////////////////////////////////////////////////////////

  getAdminLoginData(data) {
    return this.http.post(this.baseUrl + "admin/admin_login", data);
  }

  getFranchisesData() {
    return this.http.get(this.baseUrl + "franchises/getAllFranchises");
  }
  editFranchises(data) {
    return this.http.post(this.baseUrl + "franchises/update", data);
  }
  deleteFranchises(data) {
    return this.http.post(this.baseUrl + "franchises/deleteFranchises", data);
  }
  registerFranchises(data) {
    return this.http.post(this.baseUrl + "franchises/register", data);
  }

  getAllStudentsByFranchises(data) {
    return this.http.post(this.baseUrl + "students/getStudents_byFranchises", data);
  }

  approveDisapproveNewStudent(data) {
    return this.http.post(this.baseUrl + "students/approve_disapprove_Student", data);
  }
  editStudent(data) {
    return this.http.post(this.baseUrl + "students/update", data);
  }

  deleteStudent(data) {
    return this.http.post(this.baseUrl + "students/delete", data);
  }

  deleteStudentExamdetails(data) {
    return this.http.post(this.baseUrl + "students/delete_student_exam_details", data);
  }

  approveFranchisesRequest(data) {
    return this.http.post(this.baseUrl + "franchises/approveFranchise", data);
  }
  addQuestion(question) {
    return this.http.post(this.baseUrl + "questions/add_question", question);
  }
  getQuestion(language) {
    return this.http.post(this.baseUrl + "questions/get_question_for_admin", language);
  }
  editQuestion(data) {
    return this.http.post(this.baseUrl + "questions/update_question", data);
  }
  deleteQuestion(data) {
    return this.http.post(this.baseUrl + "questions/delete_question", data);
  }
  getResult(data) {
    return this.http.post(this.baseUrl + "results/get_result", data);
  }
  getOneStudentWithExamDetail(data) {
    return this.http.post(this.baseUrl + "students/getStudent_byFranchises_withExamDetail", data);
  }
  editResult(data) {
    return this.http.post(this.baseUrl + "results/update_result", data);
  }
  directAddedResult(data) {
    return this.http.post(this.baseUrl + "results/directAdded_result", data);
  }



  //! inquiry
  getFranchisesInquiry() {
    return this.http.get(this.baseUrl + "inquiry_franchises/get_inquiry_franchises");
  }
  getStudentsInquiry() {
    return this.http.get(this.baseUrl + "inquiry_students/get_inquiry_students");
  }

  //!get only length of franchises & student for dashboard
  getLength() {
    return this.http.get(this.baseUrl + "students/get_admin_dashboard");
  }
  //!New(null) students pagination
  newStudents(franchises_id: string, page: number, pageSize: number) {
    return this.http.get(this.baseUrl + `students/pagination_getNew_students?franchises_id=${franchises_id}&page=${page}&limit=${pageSize}`);
  }

  getNewStudents(page: number, pageSize: number) {
    return this.http.get(this.baseUrl + `students/pagination_getNew_students_byAll_franchises?page=${page}&limit=${pageSize}`);
  }

  getPendingTestStudents(page: number, pageSize: number) {
    return this.http.get(this.baseUrl + `students/get_pending_test_students?page=${page}&limit=${pageSize}`);
  }

  //!Approve students pagination
  approvedStudents(franchises_id: string, page: number, pageSize: number) {
    return this.http.get(this.baseUrl + `students/pagination_getApproved_students?franchises_id=${franchises_id}&page=${page}&limit=${pageSize}`,);
  }
  //!Disapprove students pagination
  disapprovedStudents(franchises_id: string, page: number, pageSize: number) {
    return this.http.get(this.baseUrl + `students/pagination_getDisapproved_students?franchises_id=${franchises_id}&page=${page}&limit=${pageSize}`);
    // return this.http.get(this.baseUrl + "students/pagination_getDisapproved_students/", data);
  }
  //! all franchises pagination
  getAllFranchisesPagination() {
    return this.http.get(this.baseUrl + "franchises/getAllFranchises_pagination/");
  }
  //!get single student
  getSingleStudent(data) {
    return this.http.post(this.baseUrl + "students/get_single_student", data);
  }
  //!get single franchise
  getSingleFranchise(data) {
    return this.http.post(this.baseUrl + "franchises/get_single_franchise", data);
  }

  //!search api for new student
  searchNewStudent(data) {
    return this.http.post(this.baseUrl + "students/search_new_student", data);
  }
  //!search api for approve student
  searchApproveStudent(data) {
    return this.http.post(this.baseUrl + "students/search_approved_student", data);
  }
  //!search api for disapprove student
  searchDisapproveStudent(data) {
    return this.http.post(this.baseUrl + "students/search_disapproved_student", data);
  }
  //!search api for franchises
  searchFranchise(data) {
    return this.http.post(this.baseUrl + "franchises/search_franchise", data);
  }
  //!add banner
  addBanner(data) {
    return this.http.post(this.baseUrl + "banner/add_banner", data);
  }
  //!get banner
  getBanner() {
    return this.http.get(this.baseUrl + "banner/get_banner");
  }
  //!delete banner
  deleteBanner(data) {
    return this.http.post(this.baseUrl + "banner/delete_banner", data);
  }
  //!add gallery
  addGallery(data) {
    return this.http.post(this.baseUrl + "gallery/add_galleryImage", data);
  }
  //!get gallery
  getGallery() {
    return this.http.get(this.baseUrl + "gallery/get_galleryImage");
  }
  //!delete gallery
  deleteGallery(data) {
    return this.http.post(this.baseUrl + "gallery/delete_galleryImage", data);
  }

  getExmCompletedStudent(page: number, pageSize: number) {
    return this.http.get(this.baseUrl + `students/get_all_exam_student??page=${page}&limit=${pageSize}`);
  }

  getSingleStudentResult(data) {
    return this.http.post(this.baseUrl + "students/get_single_student_result", data);
  }

  searchExamStudent(data) {
    return this.http.post(this.baseUrl + "students/search_exam_student", data);
  }

  filterByFranchises(data: any) {
    return this.http.post(this.baseUrl + "franchises/filter_data_by_franchise", data);
  }

  //! add Youtube link
  addLink(data) {
    return this.http.post(this.baseUrl + "youtube_links/addLink", data);
  }

  addSubject(data) {
    return this.http.post(this.baseUrl + "subject/add_subject", data);
  }

  deleteSubject(data) {
    return this.http.post(this.baseUrl + "subject/delete_subject", data);
  }

  getAllSubject() {
    return this.http.get(this.baseUrl + "subject/get_all_subjects");
  }

  addCourse(data) {
    return this.http.post(this.baseUrl + "course/add_course", data);
  }

  deleteCourse(data) {
    return this.http.post(this.baseUrl + "course/delete_course", data);
  }

  getAllCourse() {
    return this.http.get(this.baseUrl + "course/get_all_courses");
  }

  //! get Youtube links
  getLink() {
    return this.http.get(this.baseUrl + "youtube_links/getLinks");
  }

  //!delete link
  deleteLink(data) {
    return this.http.post(this.baseUrl + "youtube_links/deleteLink", data);
  }

  uploadImageToBunny(data) {
    var myHeaders = new HttpHeaders({
      'content-type': data.mime,
      'AccessKey': '7b65db0e-6ee2-4675-92906253db83-6457-4232'
    });
    return this.http.put('https://storage.bunnycdn.com/mkstoragezone/usermedia/' + data.name, data.formData, { headers: myHeaders, reportProgress: true, observe: 'events' }).pipe(catchError(this.errorMgmt));
  }


  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
